<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col md="6">
        <b-card no-body>
          <b-card-header>
            <i class="icon-info"></i> Informações do Cadastro
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col>
                <b-form-group label="Razão Social" label-for="razao_social">
                  <b-form-input
                    id="razao_social"
                    type="text"
                    :value="cliente.razao_social"
                    placeholder="Razão Social"
                    readonly
                  />
                </b-form-group>
                <b-form-group label="Nome Fantasia" label-for="nome_fantasia">
                  <b-form-input
                    id="nome_fantasia"
                    type="text"
                    :value="cliente.nome_fantasia"
                    placeholder="Nome Fantasia"
                    readonly
                  />
                </b-form-group>
                <b-row>
                  <b-col md="4">
                    <b-form-group label="CNPJ" label-for="cnpj">
                      <b-form-input
                        id="cnpj"
                        type="text"
                        :value="cliente.cnpj | cnpj"
                        placeholder="CNPJ"
                        readonly
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Telefone"
                      label-for="ddd_telefone_comercial"
                    >
                      <b-form-input
                        id="ddd_telefone_comercial"
                        type="text"
                        :value="
                          ((cliente.ddd_telefone_comercial || '').toString() +
                            (cliente.telefone_comercial || '').toString())
                            | phone
                        "
                        placeholder="Telefone"
                        readonly
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Cep" label-for="cep">
                      <b-form-input
                        id="cep"
                        type="text"
                        :value="cliente.cep | cep"
                        placeholder="Cep"
                        readonly
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col md="6">
        <b-card no-body>
          <b-card-header>
            <i class="icon-info"></i> Dados Endereço
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Endereço" label-for="endereco">
                      <b-form-input
                        id="endereco"
                        type="text"
                        :value="cliente.logradouro"
                        placeholder="Endereço"
                        readonly
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Número" label-for="numero">
                      <b-form-input
                        id="numero"
                        type="text"
                        :value="cliente.numero_do_logradouro"
                        placeholder="Número"
                        readonly
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <b-form-group label="Bairro" label-for="bairro">
                      <b-form-input
                        id="bairro"
                        type="text"
                        :value="cliente.bairro"
                        placeholder="Bairro"
                        readonly
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Cidade" label-for="cidade">
                      <b-form-input
                        id="cidade"
                        type="text"
                        :value="cliente.cidade"
                        placeholder="Cidade"
                        readonly
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Estado" label-for="estado">
                      <b-form-input
                        id="estado"
                        type="text"
                        :value="cliente.uf"
                        placeholder="Estado"
                        readonly
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <b-form-group label="Complemento" label-for="complemento">
                      <b-form-input
                        id="complemento"
                        type="text"
                        :value="cliente.complemento_logradouro"
                        placeholder="Complemento"
                        readonly
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Inscrição Estadual"
                      label-for="inscricao_estadual"
                    >
                      <b-form-input
                        id="inscricao_estadual"
                        type="text"
                        :value="cliente.inscricao_estadual"
                        placeholder="Inscrição Estadual"
                        readonly
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4"></b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col md="6">
        <b-card no-body>
          <b-card-header>
            <i class="icon-info"></i> Dados do comprador
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col>
                <b-form-group label="Nome" label-for="nome">
                  <b-form-input
                    id="nome"
                    type="text"
                    :value="cliente.contato"
                    placeholder="Nome"
                    readonly
                  />
                </b-form-group>
                <b-row>
                  <b-col md="4">
                    <b-form-group
                      label="Telefone"
                      label-for="ddd_telefone_contato"
                    >
                      <b-form-input
                        id="ddd_telefone_contato"
                        type="text"
                        :value="
                          ((cliente.ddd_telefone_contato || '').toString() +
                            (cliente.telefone_contato || '').toString())
                            | phone
                        "
                        placeholder="Telefone"
                        readonly
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Whatsapp" label-for="whatsapp">
                      <b-form-input
                        id="whatsapp"
                        type="text"
                        :value="
                          ((cliente.ddd_celular_whatsapp || '').toString() +
                            (cliente.celular_whatsapp || '').toString())
                            | phone
                        "
                        placeholder="Whatsapp"
                        readonly
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Skype" label-for="skype">
                      <b-form-input
                        id="skype"
                        type="text"
                        :value="cliente.skype"
                        placeholder="Skype"
                        readonly
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="E-mail" label-for="email">
                      <b-form-input
                        id="email"
                        type="text"
                        :value="cliente.email_contato"
                        placeholder="E-mail"
                        readonly
                      />
                    </b-form-group>
                    <b-form-group label="E-mail Nfe" label-for="email_nfe">
                      <b-form-input
                        id="email_nfe"
                        type="text"
                        :value="cliente.email_nfe"
                        placeholder="E-mail Nfe"
                        readonly
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card no-body>
          <b-card-header>
            <i class="icon-info"></i> Outras informações
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col>
                <b-form-group
                  label="Atividade Principal"
                  label-for="atividade_principal"
                >
                  <b-form-input
                    id="atividade_principal"
                    type="text"
                    :value="cliente.ramo_atividade"
                    placeholder="Atividade Principal"
                    readonly
                  />
                </b-form-group>
                <b-form-group label="Vendedor" label-for="vendedor">
                  <b-form-input
                    id="vendedor"
                    type="text"
                    :value="cliente.vendedor"
                    placeholder="Vendedor"
                    readonly
                  />
                </b-form-group>
                <b-form-group
                  label="Como conheceu a Dicomp"
                  label-for="como_conheceu"
                >
                  <b-form-input
                    id="como_conheceu"
                    type="text"
                    :value="cliente.como_conheceu_a_dicomp"
                    placeholder="Como conheceu a Dicomp"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Cliente from "../../../services/cliente";
import { EventBus } from "../../../helpers/eventBus";

export default {
  name: "Cliente",
  data: () => ({
    cliente: new Object(),
    codigo: null
  }),
  computed: {},
  beforeMount() {
    this.codigo = this.$route.params.codigo;
    this.loadClient(this.codigo);
  },
  mounted() {
    /* EventBus.$emit("onBreadcrumbButtonSet", {
      text: "Criar Novo Orçamento",
      url: "/vendas/orcamentos/orcamento/novo/" + this.codigo
    }); */
  },
  methods: {
    loadClient(codigo) {
      this.cliente = new Object();
      Cliente.getByCodigo(codigo)
        .then(response => {
          this.cliente = response.data.data;
        })
        .catch(e => {
          this.$helper.showErrorResponse(e);
        });
    }
  }
};
</script>
